import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
// http://34.28.162.112:5200/api/askAI
const subUrllocal = "/api/askAI";
class askAIService {
    askAIcompanyAPI = (data) =>
        RestfulProvider.formDatapostAI(`${subUrllocal}`, data, headers);
    getPdfforAiAPI = (data) =>
            RestfulProvider.AIPost(`/api/getPDF`, data, headers);


}
// eslint-disable-next-line
export default new askAIService();
