import { createAsyncThunk } from "@reduxjs/toolkit";
import dashboardServices from "./../../Services/dashboardServices";

export const getdashboarddetails = createAsyncThunk(
  "dashboard/getdashboarddetails",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.dashboardetailsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getvendordashboarddetails = createAsyncThunk(
  "dashboard/getvendordashboarddetails",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getvendordashboarddetailsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAdmindashboarddetails = createAsyncThunk(
  "dashboard/getAdmindashboarddetails",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getAdmindashboarddetailsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTaskStatusListData = createAsyncThunk(
  "dashboard/getTaskStatusListData",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getTaskStatusListDataAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getContractOtherStatus = createAsyncThunk(
  "dashboard/getContractOtherStatus",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getContractOtherStatusAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPieChartDeeplinkingData = createAsyncThunk(
  "dashboard/getPieChartDeeplinkingData",
  async ({ data, type }, thunkAPI) => {
    try {
      let res =
        type === "statusChart"
          ? await dashboardServices.getStatusByListDataAPI(data)
          : await dashboardServices.getDeeplinkingDataApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contractTypeVsContractCount = createAsyncThunk(
  "dashboard/contractTypeVsContractCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.contractTypeVsContractCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const dashboardModuleWisedCount = createAsyncThunk(
  "dashboard/dashboardModuleWisedCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.dashboardModuleWisedCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const contractTypeBasedCount = createAsyncThunk(
  "dashboard/contractTypeBasedCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.contractTypeBasedCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getcontractByStatusCount = createAsyncThunk(
  "dashboard/getcontractByStatusCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.contractByStatusCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHistoricalContractStatusBaseCount= createAsyncThunk(
  "dashboard/getHistoricalContractStatusBaseCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getHistoricalContractStatusBaseCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStaffwiseCount= createAsyncThunk(
  "dashboard/getStaffwiseCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getStaffwiseCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContractCount= createAsyncThunk(
  "dashboard/getContractCount",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getContractCountApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getContractsByStatusAndPeriod= createAsyncThunk(
  "dashboard/getContractsByStatusAndPeriod",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getContractsByStatusAndPeriodApi(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssignedContracts= createAsyncThunk(
  "dashboard/getAssignedContracts",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getAssignedContractsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getRecentAssignedContracts= createAsyncThunk(
  "dashboard/getRecentAssignedContracts",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getRecentAssignedContractsAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getStatusChartForAssignedContract= createAsyncThunk(
  "dashboard/getStatusChartForAssignedContract",
  async (data, thunkAPI) => {
    try {
      let res = await dashboardServices.getStatusChartForAssignedContractAPI(data);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);