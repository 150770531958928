import { createSlice } from "@reduxjs/toolkit";
import {
  getCompanydetails,
  createCompany,
  updateCompany,
  deleteCompany,
  updatepremiumfeaturesCompany,
  getCountryTimeZone,
  applyLicencingtocountry,
  getCompanyRelatedFields,
  setFieldCanbeUsedInHistorical,
  getPdfforAi,
  askAI,
} from "./AskAI.Action";

const initialState = {
  isLoading: false,
  isCreateLoading: false,
  isSuccess: false,
  isError: false,
  isMessage: null,
  allpdfFiles: [],
};
const AskAISlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    setModalVisible: (state, { payload }) => {
      state.isVisible = payload.visible;
      state.editRecord = payload.record || {};
      if (!payload.visible) {
        state.editRecord = {};
        state.companyTimeZoneData = [];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPdfforAi.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPdfforAi.fulfilled, (state, { payload }) => {
        state.allpdfFiles = payload?.pdfFiles;
        state.isLoading = false;
      })
      .addCase(getPdfforAi.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(askAI.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(askAI.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(askAI.rejected, (state, { payload }) => {
        state.isLoading = false;
      });
  },
});
export const { setModalVisible } = AskAISlice.actions;

export default AskAISlice.reducer;
