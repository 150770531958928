import { createSlice } from "@reduxjs/toolkit";

import {
  getContracts,
  // createTask,
  getbuckets,
  getContractsforcompany,
  getVendorLists,
  getContractByVendor,
  getAddendumTask,
  getContractDetails,
  getcontractVerify,
  getAcceptorrejectobligation,
  createAddendumforTasks,
  createRenewofTasks,
  getRenewalsInfoOfContract,
  getarchiveContracts,
  createChildContracts,
  getFamilyContractInfo,
  CreateRelationwithContracts,
  getLinkageDropdownList,
  // deleteTaskComment
} from "./myContract.Action";
const initialState = {
  isLoading: false,
  contractTableData: [],
  contractPagination: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  defaultcontractPagination: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  taskMgmtStatus: {
    1: {
      name: "Pending",
      // color: "#e17b27",
      color: "orange",
      id: 1,
    },
    4: {
      name: "Approval In Progress",
      color: "#01aef0",
      id: 4,
    },

    5: {
      name: "Completed",
      color: "#8ec63f",
      id: 5,
    },
    6: {
      name: "Rejected",
      color: "#800000",
      id: 6,
    },
    15: {
      name: "Internal review",
      color: "#01aef0",
      id: 15,
      tagcolor: "processing",
    },
    16: {
      name: "Review Completed",
      color: "green",
      id: 16,
    },
  },
  ismycontractviewVisible: false,
  contractviewdetails: {},
  companycontractTableData: [],
  filterpopovervisible: false,
  filtervendorpopovervisible: false,
  bucketsname: false,
  iscompanycontractSuccess: false,
  iscontractSuccess: false,
  is_active_View: "default",
  isDetailDrawer: false,
  ticketDetails: {},
  vendorListData: [],
  vendorTableLoading: false,
  vendorContractList: [],

  addendumTaskLoading: false,
  addendumTaskData: [],

  ismyaddednDumviewVisible: false,
  addendumViewdetails: {},

  isDetailLoading: false,
  contractDetails: {},
  vendorActivePanel: "",
  vendor_contractPagination: {
    total: 0,
    pageSize: 10,
    defaultCurrent: 1,
    current: 1,
    sortBy: "DESC",
    showSizeChanger: true,
    search: "",
    orderBy: "createdAt",
  },
  isContractVerifyModalvisible: false,
  iscontractverifing: false,
  iscompanyfilterApplied: false,
  isvendorfilterApplied: false,
  obligationviewmodalVisible: false,
  isacceptorrejectedLoading: false,
  isAcceptingorRejecting: false,
  isobligationrejectmodalVisible: false,
  obligationrejectmodalRecord: {},
  isaddendummodalVisible: false,
  isaddendumCreating: false,
  istaskrenewCreating: false,
  isRenewmodalVisible: false,
  isrenewsuccessfull: false,
  vendorsloading: false,
  renewalInfo: {},
  isChildCreationModalVisible: false,
  childCreationModalType: "",
  archiveContractsList: [],
  familyContractInfo: [],
  isFamilyContractInfoLoading: false,
  ischildCreating: false,
  LinkageDropdownList: [],
  isLinkageDropdownListLoading: false,
  AnalysisreportModalVisible: false,
  isAnalysisLoading: false,
  AnalysisreportRecord: {},
};
const myContractsSlice = createSlice({
  name: "myContracts",
  initialState: initialState,
  reducers: {
    setchecklistModal: (state, { payload }) => {
      state.isChecklistVisible = payload.visible;
    },
    setAnalysisreportModalVisible: (state, { payload }) => {
      state.AnalysisreportModalVisible = payload?.visible;
      state.AnalysisreportRecord = payload?.record || {};
      state.isAnalysisLoading = payload?.loading || false;
    },
    defaultView: (state, { payload }) => {
      state.is_active_View = payload;
      state.contractPagination = {
        ...state.contractPagination,
        total: 0,
        pageSize: 10,
        defaultCurrent: 1,
        current: 1,
        sortBy: "DESC",
        showSizeChanger: true,
        search: "",
        orderBy: "createdAt",
      };
    },
    setValue: (state, { payload }) => {
      state[payload.key] = payload.value;
    },
    onPageVendorChangesFilter: (state, { payload }) => {
      state.vendor_contractPagination = {
        ...state.vendor_contractPagination,
        current: payload.current,
        pageSize: payload.size,
      };
    },
    setmyContractModal: (state, { payload }) => {
      state.ismycontractviewVisible = payload.visible;
      state.contractviewdetails = payload.record || {};
    },
    setmyAddendumModal: (state, { payload }) => {
      state.ismyaddednDumviewVisible = payload.visible;
      state.addendumViewdetails = payload.record || {};
      state.addendumTaskData = payload.addendums || [];
    },
    setContractViewModal: (state, { payload }) => {
      state.isContractViewVisible = payload.visible;
      state.contractViewDetails = payload.record || {};
    },
    onPageChangesFilter: (state, { payload }) => {
      state.contractPagination = {
        ...state.contractPagination,
        current: payload.current,
        pageSize: payload.size,
      };
    },
    filterchangepagination: (state, { payload }) => {
      if (Object.keys(payload).length === 0) {
        state.contractPagination = {
          ...state.defaultcontractPagination,
        };
      } else {
        state.contractPagination = {
          ...state.contractPagination,
          ...payload,
        };
      }
    },
    vendorfilterchangepagination: (state, { payload }) => {
      if (Object.keys(payload).length === 0) {
        state.contractPagination = {
          ...state.defaultcontractPagination,
        };
      } else {
        state.contractPagination = {
          ...state.contractPagination,
          ...payload,
        };
      }
    },
    onSearchList: (state, action) => {
      state.contractPagination = {
        ...state.contractPagination,
        search: action.payload.search || "",
      };
    },
    Contractdatareset: (state, action) => {
      return initialState;
    },
    filterpopoverhandleOpenChange: (state, { payload }) => {
      state.filterpopovervisible = payload;
    },
    filtervendorpopoverhandleOpenChange: (state, { payload }) => {
      state.filtervendorpopovervisible = payload;
    },
    setcontractsuccessModal: (state, { payload }) => {
      state.iscontractsuccessVisible = payload;
    },
    openDetailDrawer: (state, { payload }) => {
      state.isDetailDrawer = payload.visible;
      state.ticketDetails = payload.record || {};
    },
    setContractVerifyModal: (state, { payload }) => {
      state.isContractVerifyModalvisible = payload.visible;
      // state.iscustomconfirmdata = payload.record || {};
    },
    getcompanyfilterActive: (state, { payload }) => {
      state.iscompanyfilterApplied = payload;
      // state.iscustomconfirmdata = payload.record || {};
    },
    getvendorfilterActive: (state, { payload }) => {
      state.isvendorfilterApplied = payload;
      // state.iscustomconfirmdata = payload.record || {};
    },
    setobligationviewmodalVisible: (state, { payload }) => {
      state.obligationviewmodalVisible = payload.visible;
      // state.iscustomconfirmdata = payload.record || {};
    },
    setobligationRejectmodalVisible: (state, { payload }) => {
      state.isobligationrejectmodalVisible = payload.visible;
      state.obligationrejectmodalRecord = payload.record || {};
    },
    setcreateaddendumModelVisible: (state, { payload }) => {
      state.isaddendummodalVisible = payload.visible;
      // state.obligationrejectmodalRecord = payload.record || {};
    },
    setcreateRenewModelVisible: (state, { payload }) => {
      state.isRenewmodalVisible = payload.visible;
      // state.obligationrejectmodalRecord = payload.record || {};
    },
    setChildCreationModalVisible: (state, { payload }) => {
      state.isChildCreationModalVisible = payload.visible;
      state.childCreationModalType = payload?.type || "";
      // state.obligationrejectmodalRecord = payload.record || {};
    },
    setChildCreationModalType: (state, { payload }) => {
      state.childCreationModalType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContracts.pending, (state) => {
        state.isLoading = true;
        state.contractTableData = [];
      })
      .addCase(getContracts.fulfilled, (state, { payload }) => {
        state.contractTableData = payload.data;
        state.isLoading = false;
        state.filtervendorpopovervisible = false;
        state.totalCount = payload.total;
        state.contractPagination = {
          ...state.contractPagination,
          total: payload.total,
        };
      })
      .addCase(getContracts.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getContractsforcompany.pending, (state) => {
        state.isLoading = true;
        state.companycontractTableData = [];
      })
      .addCase(getContractsforcompany.fulfilled, (state, { payload }) => {
        state.companycontractTableData = payload.data;
        state.isLoading = false;
        state.filterpopovervisible = false;
        state.totalCount = payload.total;
        state.contractPagination = {
          ...state.contractPagination,
          total: payload.total,
        };
      })
      .addCase(getContractsforcompany.rejected, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(getbuckets.pending, (state) => {})
      .addCase(getbuckets.fulfilled, (state, { payload }) => {
        state.bucketsname = payload.data;
      })
      .addCase(getbuckets.rejected, (state, { payload }) => {})
      .addCase(getVendorLists.pending, (state) => {
        state.vendorsloading = true;
      })
      .addCase(getVendorLists.fulfilled, (state, { payload }) => {
        state.vendorListData = payload.data;
        state.vendorsloading = false;
      })
      .addCase(getVendorLists.rejected, (state, { payload }) => {
        state.vendorsloading = false;
      })

      .addCase(getContractByVendor.pending, (state) => {
        state.vendorTableLoading = true;
        state.vendorContractList = [];
      })
      .addCase(getContractByVendor.fulfilled, (state, { payload }) => {
        state.vendorContractList = payload.data;
        state.vendor_contractPagination = {
          ...state.vendor_contractPagination,
          total: payload.total,
        };
        state.vendorTableLoading = false;
      })
      .addCase(getContractByVendor.rejected, (state, { payload }) => {
        state.vendorTableLoading = false;
      })

      .addCase(getAddendumTask.pending, (state) => {
        state.addendumTaskLoading = true;
      })
      .addCase(getAddendumTask.fulfilled, (state, { payload }) => {
        state.addendumTaskData = payload.data;
        state.addendumTaskLoading = false;
      })
      .addCase(getAddendumTask.rejected, (state, { payload }) => {
        state.addendumTaskLoading = false;
      })
      .addCase(getContractDetails.pending, (state) => {
        state.isDetailLoading = true;
        state.contractDetails = {};
      })
      .addCase(getContractDetails.fulfilled, (state, { payload }) => {
        state.contractDetails =
          payload.data && Array.isArray(payload.data) && payload.data.length > 0
            ? payload.data[0]
            : {};
        state.isDetailLoading = false;
      })
      .addCase(getContractDetails.rejected, (state, { payload }) => {
        state.isDetailLoading = false;
      })
      .addCase(getcontractVerify.pending, (state) => {
        state.iscontractverifing = true;
      })
      .addCase(getcontractVerify.fulfilled, (state, { payload }) => {
        state.iscontractverifing = false;
      })
      .addCase(getcontractVerify.rejected, (state, { payload }) => {
        state.iscontractverifing = false;
      })
      .addCase(getAcceptorrejectobligation.pending, (state) => {
        state.isacceptorrejectedLoading = true;
      })
      .addCase(getAcceptorrejectobligation.fulfilled, (state, { payload }) => {
        state.isacceptorrejectedLoading = false;
        state.isAcceptingorRejecting = true;
        state.isobligationrejectmodalVisible = false;
        state.obligationrejectmodalRecord = {};
      })
      .addCase(getAcceptorrejectobligation.rejected, (state, { payload }) => {
        state.isacceptorrejectedLoading = false;
      })
      .addCase(createAddendumforTasks.pending, (state) => {
        state.isaddendumCreating = true;
      })
      .addCase(createAddendumforTasks.fulfilled, (state, { payload }) => {
        state.isaddendumCreating = false;
        state.isaddendummodalVisible = false;
      })
      .addCase(createAddendumforTasks.rejected, (state, { payload }) => {
        state.isaddendumCreating = false;
      })
      .addCase(createRenewofTasks.pending, (state) => {
        state.istaskrenewCreating = true;
      })
      .addCase(createRenewofTasks.fulfilled, (state, { payload }) => {
        state.istaskrenewCreating = false;
        state.isrenewsuccessfull = true;

        state.isRenewmodalVisible = false;
      })
      .addCase(createRenewofTasks.rejected, (state, { payload }) => {
        state.istaskrenewCreating = false;
      })
      .addCase(getRenewalsInfoOfContract.pending, (state) => {})
      .addCase(getRenewalsInfoOfContract.fulfilled, (state, { payload }) => {
        state.renewalInfo = payload?.data;
      })
      .addCase(getRenewalsInfoOfContract.rejected, (state, { payload }) => {})
      .addCase(getarchiveContracts.pending, (state) => {})
      .addCase(getarchiveContracts.fulfilled, (state, { payload }) => {
        state.archiveContractsList = payload?.data;
      })
      .addCase(getarchiveContracts.rejected, (state, { payload }) => {})
      .addCase(createChildContracts.pending, (state) => {})
      .addCase(createChildContracts.fulfilled, (state, { payload }) => {
        // state.archiveContractsList = payload?.data;
      })
      .addCase(createChildContracts.rejected, (state, { payload }) => {})
      .addCase(getFamilyContractInfo.pending, (state) => {
        state.isFamilyContractInfoLoading = true;
      })
      .addCase(getFamilyContractInfo.fulfilled, (state, { payload }) => {
        state.isFamilyContractInfoLoading = false;
        state.familyContractInfo = payload?.data[0] || [];
      })
      .addCase(getFamilyContractInfo.rejected, (state, { payload }) => {
        state.isFamilyContractInfoLoading = false;
      })
      .addCase(CreateRelationwithContracts.pending, (state) => {
        state.ischildCreating = true;
      })
      .addCase(CreateRelationwithContracts.fulfilled, (state, { payload }) => {
        state.isChildCreationModalVisible = false;
        state.ischildCreating = false;
        state.contractDetails = payload?.data;
      })
      .addCase(CreateRelationwithContracts.rejected, (state, { payload }) => {
        state.ischildCreating = false;
      })
      .addCase(getLinkageDropdownList.pending, (state) => {
        // state.ischildCreating = true;
        state.isLinkageDropdownListLoading = true;
      })
      .addCase(getLinkageDropdownList.fulfilled, (state, { payload }) => {
        // state.isChildCreationModalVisible = false;
        // state.ischildCreating = false;
        state.isLinkageDropdownListLoading = false;

        state.LinkageDropdownList = payload?.data;
      })
      .addCase(getLinkageDropdownList.rejected, (state, { payload }) => {
        // state.ischildCreating = false;
        state.isLinkageDropdownListLoading = false;
      });
  },
});
export const {
  setmyContractModal,
  setModalVisible,
  onPageChangesFilter,
  onSearchList,
  Contractdatareset,
  filterpopoverhandleOpenChange,
  filtervendorpopoverhandleOpenChange,
  setcontractsuccessModal,
  filterchangepagination,
  vendorfilterchangepagination,
  defaultView,
  openDetailDrawer,
  setmyAddendumModal,
  setValue,
  onPageVendorChangesFilter,
  setContractVerifyModal,
  getcompanyfilterActive,
  getvendorfilterActive,
  setobligationviewmodalVisible,
  setobligationRejectmodalVisible,
  setcreateaddendumModelVisible,
  setcreateRenewModelVisible,
  setChildCreationModalVisible,
  setChildCreationModalType,
  setAnalysisreportModalVisible
} = myContractsSlice.actions;

export default myContractsSlice.reducer;
