import { createAsyncThunk } from "@reduxjs/toolkit";
import { responseMessageHandler } from "../../globals/components/notification";
import legalAssociateService from "../../Services/legalAssociateService";
import flowServices from "../../Services/flowServices";

export const getTaskBatchList = createAsyncThunk(
  "legal/getTaskBatchList",
  async (data, thunkAPI) => {
    try {
      let res = await legalAssociateService.getBatchListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getBatchwiseContracts = createAsyncThunk(
  "legal/getBatchwiseContracts",
  async (data, thunkAPI) => {
    try {
      let res = await legalAssociateService.getBatchwiseContractsAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const submitExcelFile = createAsyncThunk(
  "legal/submitExcelFile",
  async ({ data, batchData, batchwiseData }, thunkAPI) => {
    try {
      const res = await flowServices.submitFileUploadExcelAPI(data);
      thunkAPI.dispatch(getBatchwiseContracts(batchwiseData));
      thunkAPI.dispatch(getTaskBatchList(batchData));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
