import { createAsyncThunk } from "@reduxjs/toolkit";
import flowServices from "./../../Services/flowServices";
import { responseMessageHandler } from "../../globals/components/notification";
import { getChatRoomApi } from "../users/userActions";
import { ClearnewhistoricalData } from "../historicalFlowSettings/historicalservice.Slice";
import { setbulkLegacyImportVisible } from "./historicalFlow.slice";
import { message } from "antd";
export const getTaskdetails = createAsyncThunk(
  "flow/getTaskdetails",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getHistoricalListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });
      // thunkAPI.dispatch(getChatRoomApi())

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const historicalFlowCreate = createAsyncThunk(
  "flow/createricalFlow",
  async (payload, thunkAPI) => {
    try {
      const { form_data, historicalTaskPagination } = payload;
      const res = await flowServices.createHistoricalContract(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updatehistoricalFlow = createAsyncThunk(
  "flow/updatehistoricalFlow",
  async ({ data, historicalTaskPagination }, thunkAPI) => {
    try {
      const res = await flowServices.createstaffAPI(data);
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      thunkAPI.dispatch(getChatRoomApi());
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "flow/deleteTask",
  async ({ data, historicalTaskPagination }, thunkAPI) => {
    try {
      const res = await flowServices.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Resetpassword = createAsyncThunk(
  "flow/Resetpassword",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.ResetpasswordApi(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHistoricaljsondata = createAsyncThunk(
  "flow/getHistoricaljsondata",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getHistoricaljsondataAPI({});
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const updateHistoricaljsondefaultdata = createAsyncThunk(
  "flow/updateHistoricaljsondata",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.updateHistoricaljsondataAPI(data);
      thunkAPI.dispatch(getHistoricaljsondata());
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const CreatehistoricalContracts = createAsyncThunk(
  "flow/CreatehistoricalContracts",
  async (payload, thunkAPI) => {
    try {
      const {
        form_data,
        historicalTaskPagination,
        ImportsItemByIdListsPagination = {},
        groupData,
      } = payload;
      const res = await flowServices.CreatehistoricalContractsAPI(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      if (groupData?._id) {
        const data = {
          ...ImportsItemByIdListsPagination,
          instanceId: `${groupData?._id}`,
          page: 1,
          limit: 10,
          sortBy: "createdAt",
          orderBy: "ASC",
        };
        thunkAPI.dispatch(getAllImportsItemById(data));
      } else {
        thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      }
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const GetbufferdatafromUrl = createAsyncThunk(
  "flow/GetbufferdatafromUrl",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.GetbufferdatafromUrlAPI(data);

      var file = new Blob([res], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(file);

      return fileURL;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreatehistoricalContractsAddendum = createAsyncThunk(
  "flow/CreatehistoricalContractsAddendum",
  async (payload, thunkAPI) => {
    try {
      const { form_data, historicalTaskPagination } = payload;
      const res = await flowServices.CreatehistoricalContractsAPI(form_data);
      thunkAPI.dispatch(ClearnewhistoricalData());
      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));
      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHistoricalDatabyId = createAsyncThunk(
  "flow/getHistoricalDatabyId",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getHistoricalListAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const CreateBulkHistorical = createAsyncThunk(
  "flow/CreateBulkHistorical",

  async (
    { data, historicalTaskPagination, ImportListPagiantion },
    thunkAPI
  ) => {
    try {
      const res = await flowServices.CreateBulkHistoricalAPI(data);
      thunkAPI.dispatch(
        setbulkLegacyImportVisible({
          visible: false,
        })
      );
      thunkAPI.dispatch(
        getImportsList({
          ...ImportListPagiantion,
        })
      );

      thunkAPI.dispatch(getTaskdetails(historicalTaskPagination));

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getExcelforBulkHistorical = createAsyncThunk(
  "flow/getExcelforBulkHistorical",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getExcelforBulkHistoricalAPI(data);
      const url = URL.createObjectURL(new Blob([res]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "importrecord.xlsx");

      link.click();
      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getImportsList = createAsyncThunk(
  "flow/getImportsList",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getImportsListAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getImportItemById = createAsyncThunk(
  "flow/getImportItemById",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getImportItemByIdAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const submitFileUploadExcel = createAsyncThunk(
  "flow/submitFileUploadExcel",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.submitFileUploadExcelAPI(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const deleteTaskForGroup = createAsyncThunk(
  "flow/deleteTaskForGroup",
  async (
    { data, historicalTaskPaginationbygroupById, ImportListPagiantion },
    thunkAPI
  ) => {
    try {
      const res = await flowServices.deleteTaskApi(data);
      responseMessageHandler(res);
      thunkAPI.dispatch(getImportItemById(historicalTaskPaginationbygroupById));
      thunkAPI.dispatch(getImportsList(ImportListPagiantion));

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getconnectwithGoogledrive = createAsyncThunk(
  "flow/getconnectwithGoogledrive",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getconnectwithGoogledriveAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getTokenforGoogledrive = createAsyncThunk(
  "flow/getTokenforGoogledrive",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getTokenforGoogledriveAPI(data);

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const Sendfilesandfolder = createAsyncThunk(
  "flow/Sendfilesandfolder",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.SendfilesandfolderAPI(data);

      responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getLegalAssociates = createAsyncThunk(
  "flow/getLegalAssociates",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getLegalAssociatesAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAssignTasktoLegalAssociates = createAsyncThunk(
  "flow/getAssignTasktoLegalAssociates",
  async ({ data, instanceId = "" }, thunkAPI) => {
    try {
      let res = await flowServices.getAssignTasktoLegalAssociatesAPI({
        ...data,
      });
      thunkAPI.dispatch(
        getAllImportsItemById({
          sortBy: "DESC",
          instanceId: instanceId,
        })
      );
      thunkAPI.dispatch(
        getDistributedcontractDetails({
          instanceId: instanceId,
        })
      );
      responseMessageHandler(res);

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getReAssignTasktoLegalAssociates = createAsyncThunk(
  "flow/getReAssignTasktoLegalAssociates",
  async (data, thunkAPI) => {
    try {
      let res = await flowServices.getReAssignTasktoLegalAssociatesAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getAllImportsItemById = createAsyncThunk(
  "flow/getAllImportsItemById",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getImportItemByIdAPI({
        ...data,
        limit: data.pageSize,
        page: data.current,
      });

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDistributedcontractDetails = createAsyncThunk(
  "flow/getDistributedcontractDetails",
  async (data, thunkAPI) => {
    try {
      const res = await flowServices.getDistributedcontractDetailsAPI({
        ...data,
      });

      // responseMessageHandler(res);
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const unassignandReassignContractToLegalAssociate = createAsyncThunk(
  "flow/unassignandReassignContractToLegalAssociate",
  async (
    { data, ImportsItemByIdListsPagination, isCustomMessage },
    thunkAPI
  ) => {
    try {
      const res = await flowServices.unassignContractToLegalAssociateAPI({
        ...data,
      });
      thunkAPI.dispatch(
        getDistributedcontractDetails({
          instanceId: data?.instanceId || "",
        })
      );
      thunkAPI.dispatch(getAllImportsItemById(ImportsItemByIdListsPagination));
      if (isCustomMessage) {
        message.success("Contract unassigned successfully");
      } else {
        responseMessageHandler(res);
      }
      return res;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.error) ||
        error.message ||
        error.toString();
      responseMessageHandler(error.response.data);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
