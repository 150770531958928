import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { aicreatedData } from "../Content/Drawer/aidatahelper";
import { chatGroups, tabledatasource } from "./AIhelper";

import {
  ShareAltOutlined,
  SendOutlined,
  PaperClipOutlined,
  GlobalOutlined,
  CopyOutlined,
  ToolOutlined,
  ArrowsAltOutlined,
  FileTextOutlined,
  CloseOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  EditOutlined,
  FilePdfOutlined,
  MinusOutlined,
  DeleteOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  SearchOutlined,
  CopyFilled,
} from "@ant-design/icons";
import "./index.less";
import { connect, useDispatch } from "react-redux";
import { getContent } from "../Content/content.Action";
import { injectIntl } from "react-intl";
import { askAI, getPdfforAi } from "./AskAI.Action";
const { Header, Sider, Content } = Layout;
const { Text } = Typography;
const Index = (props) => {
  const {
    content,
    intl: { formatMessage },
    AskAI: { allpdfFiles = [], isLoading = false },
  } = props;
  const {
    contentTableData = [],
    // isLoading = false,
    contentPagination,
    isVisible,
    isContentVisible,
  } = content;
  const textAreaRef = useRef(null);
  const [form] = Form.useForm();
  const [prompt, setprompt] = useState("");
  const [record, setrecord] = useState({});
  const [view, setview] = useState("prompt");
  const [mode, setmode] = useState("editor");
  const [collapsed, setCollapsed] = useState(false);
  const [fileList, setfileList] = useState({});
  const [History, setHistory] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(getContent(contentPagination)).then((res) => {
  //     console.log(res.payload?.data[13]);
  //     setrecord(res.payload?.data[13]);
  //   });
  // }, []);
  const sendPromptoAI = async () => {
    // fileCheck();
    const values = await form.validateFields();
    const data = new FormData();
    console.log(values);
    data.append(`prompt`, values?.prompt);
    if (values?.pdf_file) {
      data.append(`file_name`, values?.pdf_file);
    }
    console.log(fileList);
    if (fileList?.length > 0) {
      data.append(`file`, fileList[0].originFileObj);
      form.resetFields(["pdf_file"]);
    }
    setHistory((prev) => [
      ...prev,
      {
        prompt: values.prompt,
        typeBy: "me",
        isAttachmentAvailable: fileList?.length > 0 ? true : false,
        isAttachment: fileList?.length > 0 ? fileList[0].originFileObj : null,
      },
    ]);
    form.resetFields();
    setfileList([]);
    var scrollableDiv = document.getElementById("AI-chatbox-container-01");

    function scrollToBottom() {
      if (scrollableDiv) {
        scrollableDiv.scrollTop = scrollableDiv.scrollHeight + 100;
      }
    }
    scrollToBottom();
    dispatch(askAI(data)).then((res) => {
      setHistory((prev) => [
        ...prev,
        {
          prompt: values.prompt,
          typeBy: "Ai",
          Airesponse: res.payload?.AIResponse,
        },
      ]);
    });
    setprompt(values?.prompt);
    // setCollapsed(true);
    setview("id");
  };
  const AgreementParser = (content) => {
    // Parse content
    const formattedContent = content
      ?.replace(/^### (.*)$/gm, "<h3>$1</h3>") // Replace ### with <h3>
      ?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>") // Replace **text** with <b>text</b>
      ?.replace(/\n/g, "<br>"); // Replace newlines with <br>

    return (
      <div
        className="parsed-content p-4"
        dangerouslySetInnerHTML={{ __html: formattedContent }} // Render parsed HTML
      />
    );
  };
  useEffect(() => {
    dispatch(getPdfforAi());
  }, []);
  //   async function fetchPdfAsBinary(url) {
  //     const response = await fetch(url);
  //     const arrayBuffer = await response.arrayBuffer();
  //     return new Uint8Array(arrayBuffer);
  // }

  // // Example usage
  // fetchPdfAsBinary('https://dev-verifiable-contract.s3.us-west-1.amazonaws.com/1738568517205.pdf').then(binaryData => {
  //     console.log(binaryData); // Uint8Array binary data
  // });
   const fileCheck=()=>{
  async function blobUrlToFile(blobUrl, fileName) {
    const response = await fetch(blobUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },

      mode: "no-cors",
    });
    const blob = await response.blob();
    return new File([blob], fileName, {  type: "application/pdf" });
  }

  // Example Usage:
  const blobUrl =
    "https://dev-verifiable-contract.s3.us-west-1.amazonaws.com/1738568517205.pdf";
  blobUrlToFile(blobUrl, "NM Partnership.pdf").then((file) => {
    const data = new FormData();
    data.append(`prompt`, "What is the risk regarding this contract?");
  console.log(file);
    if (file?.name) {
      data.append(`file_url`, "https://dev-verifiable-contract.s3.us-west-1.amazonaws.com/1738568517205.pdf");
    }
    dispatch(askAI(data)).then((res) => {
      console.log(res);
         setHistory((prev) => [
        ...prev,
        {
          prompt: "abcd",
          typeBy: "Ai",
          Airesponse: res.payload?.AIResponse,
        },
      ]);
    });
  });
}
  return (
    <Layout>
      <Sider
        collapsedWidth={0}
        collapsed={collapsed}
        width={250}
        trigger={null}
        collapsible
      >
        <div className="AI-sider">
          <div className="AI-sider-header">
            <div className="Ai-sider-header-title">Ask AI</div>
          </div>
          <div className="AI-sider-body">
            <div className="ai_btn_box">
              <div className="ai_search_box">
                <Input prefix={<SearchOutlined />} placeholder="Search..." />
              </div>
              <div className="chat-action-btn-container">
                <Row gutter={[0]}>
                  <Col span={24}>
                    <Button
                      style={{
                        marginBottom: "0.5rem",
                      }}
                      type="primary"
                      onClick={() => {
                        setview("prompt");
                      }}
                      block
                      icon={<PlusCircleOutlined />}
                    >
                      Start New
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Button block>Explore More...</Button>
                  </Col>
                </Row>
              </div>
            </div>

            <div className="chat-container">
              {chatGroups.map((group) => (
                <div key={group.title} className="chat-group">
                  <h2 className="group-title">{group.title}</h2>
                  {group.chats.map((chat) => (
                    <div
                      onClick={() => {
                        setview("id");
                      }}
                      key={chat.title}
                      className="chat-item"
                    >
                      <div className="chat-content">
                        <div className="chat-info">
                          <p className="chat-title">{chat.title}</p>
                          <p className="chat-time">{chat.time}</p>
                        </div>
                        {chat.unread && <div className="unread-indicator" />}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Sider>
      <Layout>
        {/* <Header>

        </Header>       */}
        <Content
          style={{
            height: "100vh",
            backgroundColor: "#fff",
          }}
        >
          {view === "prompt" && (
            <Row
              style={{
                backgroundColor: "",
                // height: "100vh",
              }}
            >
              <Col span={24}>
                <div className="AI-header-prompt">
                  <div className="AI-header-title">
                    <div>
                      {" "}
                      <Button
                        type="text"
                        icon={
                          collapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                      />
                    </div>
                    {/* <div>ASK AI</div> */}
                  </div>
                  <div>
                    <div>
                      {/* <Button
                        type="text"
                        icon={<ShareAltOutlined />}
                        size="middle"
                      >
                        share
                      </Button> */}
                      &nbsp;
                      <Avatar>HV</Avatar>
                    </div>
                  </div>
                </div>
              </Col>
              <Col offset={5} span={14}>
                <div className="AI-chat-container">
                  <div className="AI-greeting-text">What can I help with?</div>
                </div>
              </Col>
              <Col offset={5} span={14}>
                <div className="Input_container_box">
                  <div>
                    {fileList?.length > 0 && (
                      <div className="attachment-main-container">
                        <div className="attachment-container">
                          <div className="attachmnet-icon">
                            <FilePdfOutlined
                              style={{
                                color: "white",
                              }}
                            />
                          </div>
                          <div className="attachment-name-box">
                            <div className="attachment-name">
                              {fileList[0]?.name}
                            </div>
                            <div className="attachment-type">{"PDF"}</div>
                          </div>
                          <div>
                            <Button
                              onClick={() => {
                                setfileList([]);
                              }}
                              icon={
                                <DeleteOutlined
                                  style={{
                                    color: "red",
                                  }}
                                />
                              }
                              size="small"
                              type="text"
                            ></Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    <div></div>
                    <Form form={form}>
                      <Form.Item name={"prompt"}>
                        <Input.TextArea
                          ref={textAreaRef}
                          placeholder="Create with AI..."
                          autoSize={{
                            minRows: 1,
                            maxRows: 6,
                          }}
                          bordered={false}
                        ></Input.TextArea>
                      </Form.Item>
                    </Form>
                  </div>
                  <div>
                    <Row>
                      <Col span={18}>
                        <div className="extra-tools-btn">
                          <Tooltip title={"Attach files"}>
                            <Upload
                              accept="pdf"
                              maxCount={1}
                              onChange={(e) => {
                                console.log(e);
                                setfileList(e.fileList);
                              }}
                              showUploadList={false}
                            >
                              <Button
                                type="text"
                                icon={<PaperClipOutlined />}
                              ></Button>
                            </Upload>
                          </Tooltip>
                          <Tooltip title={"Web"}>
                            <Button
                              type="text"
                              icon={<GlobalOutlined />}
                            ></Button>
                          </Tooltip>
                          <Tooltip title={"Tools"}>
                            <Button
                              type="text"
                              icon={<ToolOutlined />}
                            ></Button>
                          </Tooltip>
                          <Form form={form}>
                            <Form.Item
                              style={{
                                margin: "0px",
                              }}
                              name={"pdf_file"}
                            >
                              <Select
                                placeholder={"select file"}
                                allowClear
                                style={{
                                  width: 150,
                                }}
                              >
                                {allpdfFiles.map((eve, eveIndex) => (
                                  <Select.Option key={eveIndex} value={eve}>
                                    {eve}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Form>
                        </div>
                      </Col>
                      <Col offset={4} span={2}>
                        <Button
                          loading={isLoading}
                          onClick={() => {
                            sendPromptoAI();
                          }}
                          type="text"
                          icon={<SendOutlined />}
                        ></Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
          {view === "id" && (
            <Row>
              <Col span={24}>
                <div className="AI-header">
                  <div className="AI-header-title">
                    <div>
                      {" "}
                      <Button
                        type="text"
                        icon={
                          collapsed ? (
                            <MenuUnfoldOutlined />
                          ) : (
                            <MenuFoldOutlined />
                          )
                        }
                        onClick={() => setCollapsed(!collapsed)}
                      />
                    </div>
                    <div className="Chat_title">Draft an MSA contract</div>
                  </div>
                  <div>
                    <div>
                      <Button
                        type="text"
                        icon={<ShareAltOutlined />}
                        size="middle"
                      >
                        share
                      </Button>
                      &nbsp;
                      <Avatar>HV</Avatar>
                    </div>
                  </div>
                </div>
              </Col>
              <Col className="AI_chatbox_container_main_box" span={24}>
                <div
                  id="AI-chatbox-container-01"
                  className="AI-chatbox-container-main"
                >
                  {History?.map((eve, eveIndex) => (
                    <>
                      {eve?.typeBy === "me" && (
                        <div className="AI_prompt_container_for_user">
                          {eve?.isAttachmentAvailable && (
                            <div className="attachment-main-container">
                              <div
                                style={{
                                  float: "right",
                                }}
                                className="attachment-container"
                              >
                                <div className="attachmnet-icon">
                                  <FilePdfOutlined
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                </div>
                                <div className="attachment-name-box">
                                  <div className="attachment-name">
                                    {eve?.isAttachment?.name}
                                  </div>
                                  <div className="attachment-type">
                                    {eve?.attachmentType || "PDF"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="user_prompt_container ">
                            <div className="user_prompt main-user-prompt-box">
                              {eve?.prompt}
                            </div>
                          </div>
                        </div>
                      )}
                      {eve?.typeBy !== "me" && (
                        <div className="AI_prompt_container">
                          <div>
                            <Avatar>AI</Avatar>
                          </div>

                          <div className="Ai_response">
                            <div>
                              <Card
                                style={{
                                  width: "80%",
                                  borderRadius: "10px",
                                }}
                                bodyStyle={
                                  {
                                    // maxHeight: "300px",
                                    // overflowY: "auto",
                                  }
                                }
                                hoverable
                                extra={
                                  <>
                                    <Tooltip title="Extract to Editor">
                                      <Button
                                        onClick={() => {
                                          setview("editor");
                                          setCollapsed(true);
                                          setrecord(eve);
                                        }}
                                        type="text"
                                        icon={<ArrowsAltOutlined />}
                                        size="small"
                                      ></Button>
                                    </Tooltip>{" "}
                                    <Text
                                      copyable={{
                                        text: eve?.Airesponse,

                                        format: "text/html",
                                        icon: [
                                          <CopyOutlined
                                            style={{
                                              color: "black",
                                            }}
                                            key="copy-icon"
                                          />,
                                          <CopyFilled
                                            style={{
                                              color: "black",
                                            }}
                                            key="copied-icon"
                                          />,
                                        ],
                                      }}
                                      // code
                                    >
                                      {/* {`{${ert.fieldname}}`} */}
                                    </Text>
                                  </>
                                }
                                size="small"
                                title={eve?.response}
                              >
                                <div
                                  // className="modal-content"
                                  dangerouslySetInnerHTML={{
                                    __html: eve?.Airesponse,
                                  }}
                                />
                              </Card>
                            </div>
                            <div className="Ai_response_text">
                              {eve?.responseContent}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                  {isLoading && (
                    <div className="AI_prompt_container">
                      <div>
                        <Avatar>AI</Avatar>
                      </div>

                      <div className="Ai_response">
                        <div></div>
                        <div className="Ai_response_text">
                          AI is thinking...
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="user_prompt_container ">
                    <div className="user_prompt main-user-prompt-box">
                      {"what is risk regarding this contract"}
                    </div>
                  </div>
                  <div className="AI_prompt_container">
                    <div>
                      <Avatar>AI</Avatar>
                    </div>

                    <div className="Ai_response">
                      <div></div>
                      <div className="Ai_response_text">
                        {
                          <Table
                            bordered
                            style={{
                              borderRadius: "8px",
                            }}
                            title={() => (
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                <Tooltip title="Extract to Editor">
                                  <Button
                                    onClick={() => {
                                      setview("editor");
                                      setCollapsed(true);
                                      setmode("table");
                                    }}
                                    type="text"
                                    icon={<ArrowsAltOutlined />}
                                    size="small"
                                  ></Button>
                                </Tooltip>
                              </div>
                            )}
                            size="small"
                            pagination={false}
                            dataSource={tabledatasource}
                            columns={[
                              {
                                title: "Sources",
                                dataIndex: "source",
                                key: "source",
                              },
                              {
                                title: "Risk",
                                dataIndex: "risk",
                                key: "risk",
                              },
                            ]}
                          />
                        }
                      </div>
                    </div>
                  </div> */}
                </div>

                <Col offset={5} span={14}>
                  <div className="Input_container_box chatwith_Input">
                    <div>
                      {fileList?.length > 0 && (
                        <div className="attachment-main-container">
                          <div className="attachment-container">
                            <div className="attachmnet-icon">
                              <FilePdfOutlined
                                style={{
                                  color: "white",
                                }}
                              />
                            </div>
                            <div className="attachment-name-box">
                              <div className="attachment-name">
                                {fileList[0]?.name}
                              </div>
                              <div className="attachment-type">{"PDF"}</div>
                            </div>
                            <div>
                              <Button
                                onClick={() => {
                                  setfileList([]);
                                }}
                                icon={
                                  <DeleteOutlined
                                    style={{
                                      color: "red",
                                    }}
                                  />
                                }
                                size="small"
                                type="text"
                              ></Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <Form form={form}>
                        <Form.Item name={"prompt"}>
                          <Input.TextArea
                            ref={textAreaRef}
                            placeholder="Create with AI..."
                            autoSize={{
                              minRows: 1,
                              maxRows: 6,
                            }}
                            bordered={false}
                          ></Input.TextArea>
                        </Form.Item>
                      </Form>
                    </div>
                    <div>
                      <Row>
                        <Col span={18}>
                          <div className="extra-tools-btn">
                            <Tooltip title={"Attach files"}>
                              <Upload
                                accept="pdf"
                                maxCount={1}
                                onChange={(e) => {
                                  console.log(e);
                                  setfileList(e.fileList);
                                }}
                                showUploadList={false}
                              >
                                <Button
                                  type="text"
                                  icon={<PaperClipOutlined />}
                                ></Button>
                              </Upload>
                            </Tooltip>
                            <Tooltip title={"Web"}>
                              <Button
                                type="text"
                                icon={<GlobalOutlined />}
                              ></Button>
                            </Tooltip>
                            <Tooltip title={"Tools"}>
                              <Button
                                type="text"
                                icon={<ToolOutlined />}
                              ></Button>
                            </Tooltip>
                            <Form form={form}>
                              <Form.Item
                                style={{
                                  margin: "0px",
                                }}
                                name={"pdf_file"}
                              >
                                <Select
                                  placeholder={"select file"}
                                  allowClear
                                  style={{
                                    width: 150,
                                  }}
                                >
                                  {allpdfFiles.map((eve, eveIndex) => (
                                    <Select.Option key={eveIndex} value={eve}>
                                      {eve}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Form>
                          </div>
                        </Col>
                        <Col offset={4} span={2}>
                          <Button
                            loading={isLoading}
                            onClick={() => {
                              sendPromptoAI();
                            }}
                            type="text"
                            icon={<SendOutlined />}
                          ></Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Col>
            </Row>
          )}
          <Row>
            {view === "editor" && (
              <Col span={6}>
                <div className="AI-sider-for-response">
                  <Row>
                    <Col span={24}>
                      <div className="AI-sider-for-response-header">
                        <Avatar>AI</Avatar>
                        {/* <Button size="small" icon={<ShareAltOutlined />}>
                          Share
                        </Button> */}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="AI-chatbox-container">
                        {History?.map((eve, eveIndex) => (
                          <>
                            {eve?.typeBy === "me" && (
                              <div className="AI_prompt_container_for_user">
                                {eve?.isAttachmentAvailable && (
                                  <div className="attachment-main-container">
                                    <div
                                      style={{
                                        float: "right",
                                      }}
                                      className="attachment-container"
                                    >
                                      <div className="attachmnet-icon">
                                        <FilePdfOutlined
                                          style={{
                                            color: "white",
                                          }}
                                        />
                                      </div>
                                      <div className="attachment-name-box">
                                        <div className="attachment-name">
                                          {eve?.isAttachment?.name}
                                        </div>
                                        <div className="attachment-type">
                                          {eve?.attachmentType || "PDF"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="user_prompt_container ">
                                  <div className="user_prompt main-user-prompt-box">
                                    {eve?.prompt}
                                  </div>
                                </div>
                              </div>
                            )}
                            {eve?.typeBy !== "me" && (
                              <div className="AI_prompt_container">
                                <div>
                                  <Avatar>AI</Avatar>
                                </div>

                                <div className="Ai_response">
                                  <div>
                                    <Button
                                      className="response-btn"
                                      icon={<FileTextOutlined />}
                                    >
                                      {eve?.prompt}
                                    </Button>
                                  </div>
                                  <div className="Ai_response_text">
                                    {eve?.responseContent}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                        {isLoading && (
                          <div className="AI_prompt_container">
                            <div>
                              <Avatar>AI</Avatar>
                            </div>

                            <div className="Ai_response">
                              <div></div>
                              <div className="Ai_response_text">
                                AI is thinking...
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="user_prompt_container">
                          <div className="user_prompt">
                            {"What is risk analysis according to contract"}
                          </div>
                        </div>
                        <div className="AI_prompt_container">
                          <div>
                            <Avatar>AI</Avatar>
                          </div>

                          <div className="Ai_response">
                            <div>
                              <Button
                                className="response-btn"
                                icon={<FileTextOutlined />}
                              >
                                Risk analysis report
                              </Button>
                            </div>
                            <div className="Ai_response_text">
                              The draft for the Master Services Agreement has
                              been created. Let me know if you need any
                              adjustments or additional clauses.
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col offset={1} span={22}>
                      <div className="Input_container_box">
                        <div>
                          {fileList?.length > 0 && (
                            <div className="attachment-main-container">
                              <div className="attachment-container">
                                <div className="attachmnet-icon">
                                  <FilePdfOutlined
                                    style={{
                                      color: "white",
                                    }}
                                  />
                                </div>
                                <div className="attachment-name-box">
                                  <div className="attachment-name">
                                    {fileList[0]?.name}
                                  </div>
                                  <div className="attachment-type">{"PDF"}</div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <Form form={form}>
                            <Form.Item name={"prompt"}>
                              <Input.TextArea
                                ref={textAreaRef}
                                placeholder="Create with AI..."
                                autoSize={{
                                  minRows: 1,
                                  maxRows: 6,
                                }}
                                bordered={false}
                              ></Input.TextArea>
                            </Form.Item>
                          </Form>
                        </div>
                        <div>
                          <Row>
                            <Col span={18}>
                              <div className="extra-tools-btn">
                                <Tooltip title={"Attach files"}>
                                  <Upload
                                    accept="pdf"
                                    maxCount={1}
                                    onChange={(e) => {
                                      console.log(e);
                                      setfileList(e.fileList);
                                    }}
                                    showUploadList={false}
                                  >
                                    <Button
                                      type="text"
                                      icon={<PaperClipOutlined />}
                                    ></Button>
                                  </Upload>
                                </Tooltip>
                                <Tooltip title={"Web"}>
                                  <Button
                                    type="text"
                                    icon={<GlobalOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Tooltip title={"Tools"}>
                                  <Button
                                    type="text"
                                    icon={<ToolOutlined />}
                                  ></Button>
                                </Tooltip>
                                <Form form={form}>
                                  <Form.Item
                                    style={{
                                      margin: "0px",
                                    }}
                                    name={"pdf_file"}
                                  >
                                    <Select
                                      placeholder={"select file"}
                                      allowClear
                                      style={{
                                        width: 150,
                                      }}
                                    >
                                      {allpdfFiles.map((eve, eveIndex) => (
                                        <Select.Option
                                          key={eveIndex}
                                          value={eve}
                                        >
                                          {eve}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Form>
                              </div>
                            </Col>
                            <Col offset={4} span={2}>
                              <Button
                                loading={isLoading}
                                onClick={() => {
                                  sendPromptoAI();
                                }}
                                type="text"
                                icon={<SendOutlined />}
                              ></Button>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )}

            {view === "editor" && (
              <Col span={18}>
                <Row>
                  <Col span={24}>
                    <div className="content-AI-header">
                      <div className="content-AI-header-left">
                        <Button
                          onClick={() => {
                            setview("prompt");
                            setCollapsed(false);
                          }}
                          icon={<CloseOutlined />}
                          type="text"
                        ></Button>
                        &nbsp;
                        <div className="AI-response-title">
                          {mode === "editor"
                            ? "MSA Contract Draft"
                            : "Risk analysis report"}
                        </div>
                      </div>
                      <div>
                        <div className="AI-header-extraAction">
                          <Tooltip title="copy">
                            <CopyOutlined />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="content-AI">
                      {mode === "editor" ? (
                        <div
                          // className="modal-content"
                          dangerouslySetInnerHTML={{
                            __html: record?.Airesponse,
                          }}
                        />
                      ) : (
                        <Table
                          bordered
                          style={{
                            borderRadius: "8px",
                          }}
                          title={() =>
                            mode === "editor" ? (
                              <div
                                style={{
                                  float: "right",
                                }}
                              >
                                <Tooltip title="Extract to Editor">
                                  <Button
                                    onClick={() => {
                                      setview("editor");
                                      setCollapsed(true);
                                    }}
                                    type="text"
                                    icon={<ArrowsAltOutlined />}
                                    size="small"
                                  ></Button>
                                </Tooltip>
                              </div>
                            ) : (
                              ""
                            )
                          }
                          size="small"
                          pagination={false}
                          dataSource={tabledatasource}
                          columns={[
                            {
                              title: "Sources",
                              dataIndex: "source",
                              key: "source",
                            },
                            {
                              title: "Risk",
                              dataIndex: "risk",
                              key: "risk",
                            },
                          ]}
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Content>
      </Layout>
    </Layout>
  );
};

export default connect((state) => ({
  user: state.user,
  staff: state.staff,
  template: state.template,
  templateservice: state.templateservice,
  taskmanagement: state.taskmanagement,
  global: state.global,
  content: state.content,
  contentservice: state.contentservice,
  Obligation: state.Obligation,
  AskAI: state.AskAI,
}))(injectIntl(Index));
