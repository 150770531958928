import { createSlice } from "@reduxjs/toolkit";
import {
  getBatchwiseContracts,
  getTaskBatchList,
  submitExcelFile,
} from "./LegalAssociateTask.Action";

const initialState = {
  user: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  isFilterApplied: false,
  isFiltermodalopen: false,
  isHistoricalDatinlimit: {},
  ImportLists: [],
  groupIdInfo: {},
  isGroupInfoLoading: false,
  isGroupInfoSuccess: false,
  isGroupInfoError: false,
  legalTaskPagination: {
    limit: 20,
    page: 1,
    sortBy: "createdAt",
    orderBy: "DESC",
    showSizeChanger: true,
    search: "",
    total: 0,
  },
  batchwiseTaskPagination: {
    limit: 20,
    page: 1,
    sortBy: "createdAt",
    orderBy: "DESC",
    showSizeChanger: true,
    search: "",
    total: 0,
  },
  legalAssociateTaskList: [{}],
  isViewandCompleteTaskDrawerVisible: false,
  editRecord: {},
  batchwiseTaskList: [],
  isUpdateMetaDataModalVisible: false,
  MetaDataRecords: {},
  isCheckMetaDataDrawerVisible: false,
  issubmittingExcelFile: false,
};
const legalAssociateTaskSlice = createSlice({
  name: "legalAssociateTask",
  initialState,
  reducers: {
    onSearchList: (state, action) => {
      state.legalTaskPagination = {
        ...state.legalTaskPagination,
        search: action.payload.search || "",
      };
    },
    onPageChangesFilter: (state, action) => {
      state.legalTaskPagination = {
        ...state.legalTaskPagination,
        current: action.payload.current,
        pageSize: action.payload.pageSize,
        sortBy: action.payload.order === 1 ? "ASC" : "DESC",
        search: action.payload.search,
        orderBy: action.payload.orderBy,
      };
    },
    setDrawerVisible: (state, { payload }) => {
      state.isViewandCompleteTaskDrawerVisible = payload?.visible || false;
      state.editRecord = payload?.record || {};
      if (!payload?.visible) {
        state.editRecord = {};
      }
    },
    setUpdateMetaDataModalVisible: (state, { payload }) => {
      state.isUpdateMetaDataModalVisible = payload?.visible || false;

      if (!payload?.visible) {
        // state.editRecord = {};
      }
    },
    setCheckMetaDataDrawerVisible: (state, { payload }) => {
      state.isCheckMetaDataDrawerVisible = payload?.visible || false;
      state.MetaDataRecords = payload?.record || {};
      state.isUpdateMetaDataModalVisible = false;
      if (!payload?.visible) {
        state.editRecord = {};
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTaskBatchList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTaskBatchList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.legalAssociateTaskList = action.payload.data;
        state.legalTaskPagination = {
          ...state.legalTaskPagination,
          total: action.payload.total,
        };
      })
      .addCase(getTaskBatchList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBatchwiseContracts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBatchwiseContracts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.batchwiseTaskList = action.payload.data;
        state.batchwiseTaskPagination = {
          ...state.batchwiseTaskPagination,
          total: action.payload.total,
        };
      })
      .addCase(getBatchwiseContracts.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(submitExcelFile.pending, (state) => {
        state.issubmittingExcelFile = true;
      })
      .addCase(submitExcelFile.fulfilled, (state, action) => {
        state.issubmittingExcelFile = false;
        state.isCheckMetaDataDrawerVisible = false;
        state.isUpdateMetaDataModalVisible = false;
      })
      .addCase(submitExcelFile.rejected, (state) => {
        state.issubmittingExcelFile = false;
      });
    // Add extra reducers here
  },
});
export const {
  onSearchList,
  onPageChangesFilter,
  setDrawerVisible,
  setUpdateMetaDataModalVisible,
  setCheckMetaDataDrawerVisible,
} = legalAssociateTaskSlice.actions;
export default legalAssociateTaskSlice.reducer;
