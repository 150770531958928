import RestfulProvider from "../globals/RestfulProvider";

const headers = {
  Accept: "application/json",
  "Content-type": "application/json",
  isAuthenticated: true,
};
const subUrllocal = "/api/v1/dataManager";
class DatamanagerServices {
  getFieldsListAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/getdata`, data, headers);
  CreateandUpdateDataFieldAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/create`, data, headers);
  DeleteDataFieldAPI = (data) =>
    RestfulProvider.post(`${subUrllocal}/delete`, data, headers);
}
// eslint-disable-next-line
export default new DatamanagerServices();
